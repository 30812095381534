import * as React from "react";

import RenderButton from "../RenderButton";
import AuthContext from "../../../store/auth-context";
import { useDispatch, useSelector } from "react-redux";
import { validUserInputRegex } from "../../Common/ValidInput";

const CSpStep1 = ({
  handleActiveStepChange
}) => {
  const authCtx = React.useContext(AuthContext);

  const dispatch = useDispatch();

  const apiUrl = process.env.REACT_APP_API_HOST
    ? process.env.REACT_APP_API_HOST
    : "https://sfpapi.videocreator.net";

  //Bankname vorgelegen
  const field = "institutText";

  let prevUserInput = useSelector((state) => state.userInput[field]);
  const [content, setContent] = React.useState(prevUserInput);

  const [name, setName] = React.useState("");

  const fetchName = React.useCallback(async () => {
    const res = await fetch(apiUrl + "/userinput/bankname", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    });
    const name = await res.text();

    //console.log(name);
    setName(name);

    dispatch({
      type: "addUserInput",
      payload: {
        value: name.trim(),
        field: field,
      },
    });
  }, [authCtx, apiUrl, dispatch]);

  const onChangeHandler = (event) => {

    if (!validUserInputRegex.test(event.target.value)) {
      return;
    }

    //console.log(event.target.value);
    let inputLines = event.target.value.match(/\n/g) || [];

    let count = inputLines.length;
    let modValue = event.target.value;
    while (count >= 4) {
      const lastLinebreak = modValue.lastIndexOf("\n");
      modValue = modValue.substring(0, lastLinebreak);
      count = (modValue.match(/\n/g) || []).length;
    }

    setContent(modValue);

    dispatch({
      type: "addUserInput",
      payload: {
        value: modValue.trim(),
        field: field,
      },
    });
  };

  //Sonderlogo vorgelegen
  let campaignUUID = useSelector((state) => state.campaign.uuid);
  const fieldLogo = "sonderlogoBild";

  const [standardLogos, setStandardLogos] = React.useState([]);
  const [sonderLogos, setSonderLogos] = React.useState([]);

  let prevSelectedImage = useSelector((state) => state.userInput[fieldLogo]) || "";

  let prevSelectedImageObj = sonderLogos.find((logo) => logo.link === prevSelectedImage);
  if (!prevSelectedImageObj) {
    prevSelectedImageObj = standardLogos.find((logo) => logo.link === prevSelectedImage);
  }

  const [selectedImage, setSelectedImage] = React.useState(prevSelectedImageObj || undefined);

  const handleToggle = (value) => () => {

    setSelectedImage(value);
    dispatch({
      type: "addUserInput",
      payload: {
        value: value.link,
        field: fieldLogo,
      },
    });
    let sonderlogotext = "";
    if (!value.standard) {
      sonderlogotext = "x";
      dispatch({
        type: "addUserInput",
        payload: {
          value: sonderlogotext,
          field: "sonderlogoText",
        },
      });
    } else {
      dispatch({
        type: "removeUserInput",
        payload: {
          field: "sonderlogoText",
        },
      });
      if (value.link !== standardLogos[1].link) {
        dispatch({
          type: "removeUserInput",
          payload: {
            field: "institutAlt",
          },
        });
      } else {
        dispatch({
          type: "addUserInput",
          payload: {
            value: "y",
            field: "institutAlt",
          },
        });
      }
    }
  };

  const fetchLogos = React.useCallback(async () => {
    const res = await fetch(apiUrl + "/userinput/sonderlogos", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
      body: JSON.stringify({
        campaignUuid: campaignUUID,
      }),
    });
    const logoRespnse = await res.json();

    let standardLogos = logoRespnse.filter((logo) => logo.standard === true);
    let sonderLogos = logoRespnse.filter((logo) => logo.standard === false);

    setStandardLogos(standardLogos);
    setSonderLogos(sonderLogos);

    if (!prevSelectedImageObj && (!selectedImage) && logoRespnse && logoRespnse.length >= 1) {

      handleToggle(logoRespnse[0])();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authCtx, campaignUUID, selectedImage, prevSelectedImageObj]);

  React.useEffect(() => {

    if (!prevUserInput) {
      //fetchLogos();
      fetchName();
    }
    fetchLogos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevUserInput]);





  return (
    <div className={"Step C2Step2 c-Logoauswahl"}>
      <div className={"content"}>
        <h4>
          1. Trage den Institutsnamen der Sparkasse mit korrekten Zeilenumbrüchen in das Textfeld ein:
        </h4>

        <div>
          <textarea
            className="e-textarea"
            key="0"
            width="95"
            onChange={onChangeHandler}
            rows="3"
            defaultValue={name}
            value={content}
            style={{ maxLines: 4 }}
            disabled={
              sonderLogos.length > 0 &&
              selectedImage &&
              !selectedImage.standard
            }
          ></textarea>
        </div>



        <br></br>
        <h4>2. Wähle das gewünschte Logo-Design aus:</h4>
        <div className={"c-Context"}>

          <div className={"contextImage-wrapper"}>
            {standardLogos.map((value, index) => (
              <div
                key={index}
                onClick={handleToggle(value)}
                className={"contextImageCard-small"}
              >

                <img
                  src={value.frontendLink || value.link}
                  alt={value.alttext}
                  className={selectedImage?.link === value.link ? " grey" : ""}
                />
                {value.title}
              </div>
            ))}
          </div>
        </div>

        <p>
          Weiteres Sparkassen-Sonderlogo verwenden? Sende bitte eine EPS-Datei
          (Vektorgrafik) an <a href="mailto:via@s-communication.de">via@s-communication.de</a>. S-COM teilt mit, wenn das
          Logo im System zur Verfügung steht - dies dauert gewöhnlich bis zu
          drei Werktage.
        </p>

        {sonderLogos.length > 0 && (
          <div>
            <h4>Wähle alternativ eines der aufgeführten Sparkassen-Sonderlogos für die Abbinder-Animation aus:</h4>
            <div className={"c-Context"}>

              <div className={"contextImage-wrapper"}>
                {sonderLogos.map((value, index) => (
                  <div
                    key={index}
                    onClick={handleToggle(value)}
                    className={"contextImageCard-small"}
                  >

                    <img
                      src={value.frontendLink || value.link}
                      alt={value.alttext}
                      className={selectedImage?.link === value.link ? "grey" : ""}
                    />
                    {value.title}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <div className={"c-ButtonBar"}>
          <div
            className={"e-button gray"}
            onClick={() => {
              handleActiveStepChange(0);
            }}
          >
            Zurück
          </div>
          {!selectedImage?.link ? (
            <div
              className={"e-button gray"}>
              Vorschau anzeigen
            </div>) : (
            <RenderButton
              maxWidth="400px"
              key="1"
              campaignuuid="94ce48a1-1286-48c3-a373-bb4e2816a32c"
              weiterAction={handleActiveStepChange}
              weiterActionProp={2}
            />
          )}
        </div>
      </div>

      <div className={"content-sidebar"}>
        <div className={"big-image"}>
          {selectedImage?.link ? (
            <img src={selectedImage?.frontendLink ? selectedImage.frontendLink : selectedImage?.link} alt={selectedImage?.alttext} />
          ) : (
            standardLogos.length > 0 ? (
              <img src={standardLogos[0].link} alt={standardLogos[0].alttext} />
            ) : (
              ""
            ))}
          <p>
            <b>Beispielbild</b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CSpStep1;
